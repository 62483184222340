<template>
    <div>
        <el-table
                :data="messageList"
                border
                v-loading="messageListLoading"
                style="width: 100%">
            <el-table-column
                    prop="id"
                    header-align="center"
                    align="center"
                    width="80"
                    label="序号">
            </el-table-column>
            <el-table-column
                    prop="fromPerson"
                    header-align="center"
                    align="center"
                    width="180"
                    label="发布人">
            </el-table-column>
            <el-table-column
                    prop="toPerson"
                    header-align="center"
                    align="center"
                    width="180"
                    label="收件人">
            </el-table-column>
            <el-table-column
                    prop="messageType"
                    header-align="center"
                    align="center"
                    width="180"
                    label="消息类型">
            </el-table-column>
            <el-table-column
                    prop="content"
                    header-align="center"
                    align="center"
                    :show-overflow-tooltip="true"
                    label="消息内容">
            </el-table-column>
            <el-table-column
                    prop="ctime"
                    header-align="center"
                    align="center"
                    width="280"
                    :show-overflow-tooltip="true"
                    label="发布时间">
            </el-table-column>
            <el-table-column
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="280"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="getDetailInfo(scope.row.id,scope.row)">查看详情</el-button>
                    <el-button type="text" size="small" @click="deleteMessage(scope.row.id)">删除</el-button>
                    <el-button type="text" size="small" @click="replyMessage(scope.row.id, scope.row)">回复</el-button>
                </template>
            </el-table-column>
        </el-table>
        <message-detail v-if="messageVisible" ref="MessageDetail" :messageInfo="selectMessage"></message-detail>
        <MessageReply v-if="messageReplyVisible" ref="MessageReply" :messageInfo="selectMessage"></MessageReply>
    </div>
</template>

<script>
    import MessageDetail from './message-detail'
    import MessageReply from './message-reply'
    export default {
        name: "message-list",
        data(){
            return{
                messageVisible: false,
                messageReplyVisible:false,
                messageList: [
                    {
                        'id':1,
                        'fromPerson':'管理员',
                        'toPerson':'管理员',
                        'messageType':"通知类",
                        'content':'消息内容消息内容消息内容消息内容消息内容消息内容消息内容消息内容消息内容消息内容消息内容消息内容消息内容消息内容消息内容消息内容消息内容消息内容消息内容',
                        'ctime': '2021-5-23',
                        'status': '0'
                    },
                    {
                        'id':2,
                        'fromPerson':'管理员',
                        'toPerson':'管理员',
                        'messageType':"通知类",
                        'content':'梁国一户姓杨的人家家里有一个九岁的儿子，非常聪明。孔君平来拜见他的父亲，恰巧他父亲不在，于是便叫他出来。他为孔君平端来水果，水果中有杨梅，孔君平指着杨梅给孩子看，并说：“这是你家的水果。”杨氏子马上回答说：“没听说孔雀是先生您家的鸟。”',
                        'ctime': '2021-5-23',
                        'status': '0'
                    }
                ],
                messageListLoading:false,
                selectMessage: {}
            }
        },
        components:{
            MessageDetail,
            MessageReply
        },
        methods:{
            getDetailInfo(_id, info){
                this.selectMessage = info
                this.messageVisible = true

                this.$nextTick(()=>{
                    this.$refs.MessageDetail.init()
                })
            },
            deleteMessage(_id){
                let _datas = []

                for (let i = 0;i < this.messageList.length; i++){
                    if (this.messageList[i]['id'] !== _id){
                        _datas.push(this.messageList[i])
                    }
                }
                this.messageList = _datas
                this.$message.success("删除成功")

            },
            replyMessage(_id,info){
                this.messageReplyVisible = true
                this.selectMessage = info

                this.$nextTick(()=>{
                    this.$refs.MessageReply.init()
                })
            }
        }
    }
</script>

<style scoped>

</style>
