<template>
  <el-col>
    <el-card :body-style="{ padding: '0px' }">
      <div style="padding: 14px;">
        <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
             class="image">
        <p>个人信息</p>
        <p>昵称:{{ userInfo.username }}</p>
        <p>姓名:{{ userInfo.username }}</p>
        <p>联系方式:{{ userInfo.mobile }}</p>
        <p>邮箱:{{ userInfo.email }}</p>
      </div>
    </el-card>
    <el-tabs v-model="activeName">
      <el-tab-pane label="关联资源" name="first">
      </el-tab-pane>
      <el-tab-pane label="好友" name="second">
      </el-tab-pane>
      <el-tab-pane label="申请资源" name="third">
      </el-tab-pane>
    </el-tabs>
  </el-col>
</template>

<script>
export default {
  name: "person-center-home",
  data() {
    return {
      currentDate: new Date(),
      activeName: 'first',
      userInfo: {}
    };
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    init() {
      // eslint-disable-next-line
      console.log(this.$store.state)
    },
    getUserInfo() {
      this.$http({
        url: this.$http.adornUrl('/sys/user/info'),
        method: 'get',
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.loading = false
          this.userInfo = data.user
          // eslint-disable-next-line
          console.log(data)
          // this.userId = data.user.userId
          // this.userName = data.user.username
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
