<template>
  <el-dialog
          :title="'消息详情'"
          :close-on-click-modal="false"
          :visible.sync="visible">
    <p>ID: {{messageInfo.id}}</p>
    <p>发布人: {{messageInfo.toPerson}}</p>
    <p>收件人: {{messageInfo.fromPerson}}</p>
    <p>消息类型: {{messageInfo.messageType}}</p>
    <p>消息内容: {{messageInfo.content}}</p>
    <p>创建时间: {{messageInfo.ctime}}</p>
    <template >
      <p>
        <span v-if="messageInfo.status==='0'">消息状态：发布</span>
        <span v-else>消息状态：已撤回</span>
      </p>
    </template>
    <p>发布时间: {{messageInfo.ctime}}</p>
    <span slot="footer" class="dialog-footer">
<!--      <el-button @click="visible = false">取消</el-button>-->
      <el-button type="primary" @click="visible = false">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    data () {
      return {
        visible:false,
        messageForm: {
          id: 0,
          person: '',
          messageType: '',
          content: '',
          ctime: '',
          status: '',
        },
      }
    },
    props: {
      // id:{
      //   type: String,
      //   default:''
      // },
      messageInfo: {
        type: Object,
        defaults: {}
      }
    },
    methods: {
      init(){
        this.visible = true
      },
      // 表单提交
      dataFormSubmit () {

      }
    }
  }
</script>
