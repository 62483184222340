<template>
    <el-tabs v-model="activeName">
        <el-tab-pane label="个人中心" name="first">
            <person-center-home></person-center-home>
        </el-tab-pane>
        <el-tab-pane label="消息中心" name="second">
            <message-center></message-center>
        </el-tab-pane>
        <el-tab-pane label="我的资源" name="third">
            <person-resources></person-resources>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    import PersonCenterHome from './person-center-home'
    import MessageCenter from './message-center'
    import PersonResources from './person-resources'
    export default {
        name: "person-center",
        data(){
            return{
                activeName:'first'
            }
        },
        components:{
            PersonCenterHome,
            MessageCenter,
            PersonResources
        }
    }
</script>

<style scoped>

</style>
